import Button from "@/components/buttons";
import TextComponent from "../TextComponent";
import cn from "@/utils/cn";
import { useZStore } from "@/utils/z-store";

export default function InvitationModal() {
  const { data, actions } = useZStore();
  return (
    <div
      className={cn(
        "relative rounded-lg xl:rounded-[20px] bg-white p-6 shadow-md xl:h-[278px] xl:w-[628px]",
        "flex flex-col items-center justify-center",
      )}
    >
      {/* Close Button (X) */}
      <button
        className="absolute right-4 top-4 text-gray-400 hover:text-gray-600"
        aria-label="Close"
        onClick={() => actions.closeModal()}
      >
        &times;
      </button>

      {/* Heading */}
      <TextComponent type="h2" className="mb-2 text-center text-[#05004E]">
        You Have Been Invited!
      </TextComponent>

      {/* Subtext */}
      <TextComponent
        type="body"
        className="mb-6 text-center text-[#96A5B8] xl:mb-8"
      >
        Continue to accept and get access to shared data.
      </TextComponent>

      {/* Call-to-Action Button */}
      <div className="text-center">
        <Button variant="primary">Create a Free Account</Button>
      </div>
    </div>
  );
}
