import React, { useMemo } from "react";
import { useZStore } from "@/utils/z-store";
import { DashboardDropdownActiveIcon } from "@/assets/svg-icons/svg-icons";
import ReusableDropdown from "./ReusableDropdown";
import cn from "@/utils/cn";
import axios from "axios";

interface Dashboard {
  id: string;
  dashboardId?: string;
  name: string;
  type?: "template" | "shared";
}

interface DashboardDropdownProps {
  showCreateOption?: boolean;
  invertColors?: boolean; // to keep background white on home page and background blue on edit dashboard page
  className?: string;
}
const DashboardDropdown: React.FC<DashboardDropdownProps> = ({
  showCreateOption = false,
  invertColors = false,
  className = "",
}) => {
  const { data, actions } = useZStore();

  const dashboards: any[] = useMemo(() => {
    // Handle both null and undefined cases
    if (!data?.dashboardData || !data?.dashboardData.length) return [];

    return data.dashboardData.map((dashboard) => ({
      id: dashboard.dashboardId,
      name: dashboard.name,
      type: dashboard.type || "unknown",
    }));
  }, [data.dashboardData]);

  const selectedDashboardId =
    data.selectedDashboard || data?.dashboardData?.[0]?.dashboardId;

  const handleSelect = (id: string) => {
    actions.setSelectedDashboard(id);
  };

  const handleAdd = (name: string): string => {
    const newDashboardId = actions.addDashboard(name);
    return newDashboardId;
  };

  const handleUpdate = (id: string, name: string) => {
    actions.updateDashboardName(id, name);
  };

  const handleDelete = async (id: string) => {
    try {
      actions.deleteDashboard(id);
      if (selectedDashboardId === id) {
        const remainingDashboards = dashboards.filter(
          (d) => d.dashboardId !== id,
        );
        if (remainingDashboards.length > 0) {
          handleSelect(remainingDashboards[0].id);
        }
      }

      const response = await axios.delete("/api/users/dashboard", {
        data: { dashboardId: id },
      });
      actions.setDashboardData(response.data.data);
      actions.setSelectedDashboard(response.data.selectedDashboard);
    } catch (error) {
      console.error("Error deleting dashboard:", error);
    }
  };

  return (
    <ReusableDropdown
      title="Dashboard"
      selectedItemId={selectedDashboardId}
      items={dashboards}
      onSelect={handleSelect}
      onAdd={handleAdd}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      IconOpen={DashboardDropdownActiveIcon}
      IconClosed={DashboardDropdownActiveIcon}
      placeholder="Enter dashboard name"
      minNameLength={3}
      className={cn("", className)}
      showCreateOption={showCreateOption}
      invertColors={invertColors}
      truncateLength={60}
    />
  );
};

export default DashboardDropdown;
