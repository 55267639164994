"use client";
import cn from "@/utils/cn";
import { Content } from "@prismicio/client";
import { PrismicNextImage } from "@prismicio/next";
import { PrismicLink, PrismicRichText } from "@prismicio/react";
import { Fragment, useState } from "react";
import CopyrightText from "./copyright";
import FooterLinks from "./footer-links";
import TextComponent from "../TextComponent";
import { LocationIcon, MessageIcon } from "@/assets/svg-icons/svg-icons";
import InputField from "@/components/input-field";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import Button from "@/components/buttons";
import { FaInstagram, FaTiktok, FaYoutube } from "react-icons/fa";
import { handleNewsletterSubscription } from "@/utils/actions/mailchimp-integration";

type FooterProps = {
  logo: Content.LandingPageDocumentData["footer_logo"];
  sectionContent: Content.LandingPageDocumentData["footer_content"];
  sectionAddress: Content.LandingPageDocumentData["footer_address"];
  links: Content.LandingPageDocumentData["footer_links"];
} & React.HTMLAttributes<HTMLDivElement>;

const emailSchema = z.object({
  email: z.string().min(1, "Email is required").email("Invalid email address"),
});
type EmailFormValues = z.infer<typeof emailSchema>;

export default function Footer({
  logo,
  sectionContent,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  sectionAddress, // We might need this later.
  links,
  className,
  ...props
}: FooterProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState<{
    type: "success" | "error" | null;
    message: string | null;
  }>({ type: null, message: null });
  const methods = useForm<EmailFormValues>({
    resolver: zodResolver(emailSchema),
  });

  const onSubmit = async (data: EmailFormValues) => {
    setIsLoading(true);
    try {
      const result = await handleNewsletterSubscription(data.email, true);
      if (result.error) {
        // Optional: Handle error display
        console.error("Subscription error:", result.error);
      } else {
        methods.reset(); // Reset form on success
        console.log("Successfully subscribed!");
      }
      // Handle success/error
    } catch (error) {
      // Handle error
    } finally {
      setIsLoading(false);
      methods.reset();
    }
  };
  return (
    <div
      className={cn(
        // "pt-10 pb-[29px] md:pb-[36px] xl:pb-[39px]  px-11 md:px-20 xl:px-28 w-full max-w-[1920px]s",
        "xl:pt-[94px] xl:pb-[52px] bg-[#FAFBFC] w-full flex items-center justify-center",
        "md:pt-[70px] md:pb-10",
        "pt-[50px] pb-[27px]",
        className,
      )}
      {...props}
    >
      <div
        className={cn(
          "flex md:flex-row md:space-y-0 space-y-[30px] flex-col justify-between 2xl:w-[1280px] xl:w-[1120px] md:w-[672px] w-[317px] ",
        )}
      >
        <div className=" space-y-4 md:w-1/2 xl:w-3/5 ">
          <div className="">
            <PrismicNextImage
              field={logo}
              alt=""
              className="mb-[14px] w-[140px] xl:mb-5 xl:w-[175px]"
            />
            <div className="">
              <div className="mb-[22px] md:mb-2 xl:mb-10">
                <PrismicRichText
                  field={sectionContent}
                  components={{
                    paragraph: ({ children }) => (
                      <p className="mb-2 text-[10.80px] font-normal leading-[16.2px] text-[#96A5B8] md:leading-[18px] xl:text-[14px] xl:leading-[28px]">
                        {/* {children} */}
                        Perfect for website owners, digital agencies, and anyone
                        looking to boost their email marketing. Our tool stands
                        out with fast analysis, detailed reporting, and a wide
                        range of features.
                      </p>
                    ),
                  }}
                />
              </div>
            </div>
          </div>

          <div className="">
            <TextComponent type="h3" className="font-semibold text-kd-lte-blue">
              Join Our Newsletter
            </TextComponent>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit)}>
                <div className=" mt-[18px] flex items-center space-x-5 space-y-4 xl:mt-[26px]">
                  <InputField
                    name="email"
                    type="email"
                    className={cn(
                      "xl:h-[60px] xl:w-[534px] bg-white",
                      "md:w-[238px] h-[45px]",
                      "w-[213px] h-[45px]",
                    )}
                    containerClassName="w-fit"
                    placeholder="Email"
                  />
                  <Button
                    type="submit"
                    variant="primary"
                    // className="my-auto h-[32px] whitespace-nowrap rounded-[9.6px] border border-[#61A1FF] px-[12.5px] lg:h-10 lg:rounded-[12px] lg:px-[15px]"
                    className="h-[30px] rounded-lg xl:h-10 xl:rounded-[9px]"
                  >
                    {isLoading ? "Subscribing..." : "Subscribe"}
                  </Button>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>

        <div className=" w-fit min-w-[20%]">
          <div className="">
            <TextComponent
              type="h4"
              className="mb-5 font-semibold text-kd-lte-blue xl:mb-7"
            >
              Get in Touch
            </TextComponent>
            <div className=" mt-[27px] flex items-start space-x-[12px] xl:space-x-[17px]">
              <LocationIcon className="size-[15px] xl:size-5" />
              <TextComponent className="text-kd-gray-text" type="body">
                19 W 24th Steet,
                <br />
                New York, NY 10010, USA
              </TextComponent>
            </div>
            <div className="mt-[18px] flex items-start space-x-[17px] xl:mt-[25px]">
              <MessageIcon className="size-[15px] xl:size-5" />
              <TextComponent className="text-kd-gray-text" type="body">
                hello@emailauditengine.com
              </TextComponent>
            </div>
          </div>

          <div className="mt-[60px] flex space-x-[15px]">
            <div className="flex size-[30px] cursor-pointer items-center justify-center rounded-xl border border-[#EDF2F6] bg-white p-2 xl:size-10 xl:p-2.5">
              <FaTiktok className="size-full text-kd-lte-blue" />
            </div>
            <div className="flex size-[30px] cursor-pointer items-center justify-center rounded-xl border border-[#EDF2F6] bg-white p-2 xl:size-10 xl:p-2.5">
              <FaInstagram className="size-full text-kd-lte-blue" />
            </div>
            <div className="flex size-[30px] cursor-pointer items-center justify-center rounded-xl border border-[#EDF2F6] bg-white p-2 xl:size-10 xl:p-2.5">
              <FaYoutube className="size-full text-kd-lte-blue" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
