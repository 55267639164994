export interface FormConfigType {
  ownerEmail: any;
  layout: {
    width: string;
    padding: string;
    borderRadius: string;
    backgroundColor: string;
  };
  header: {
    title: string;
    className?: string;
    visible: boolean;
    alignment?: "left" | "center" | "right";
  };
  description: {
    text: string;
    className?: string;
    visible: boolean;
    alignment?: "left" | "center" | "right";
  };
  formFields: {
    type: string;
    name: string;
    label: string;
    placeholder?: string;
    required: boolean;
    errorText: string;
    width: string;
    className?: string;
    defaultValue?: string;
  }[];
  button: {
    text: string;
    className?: string;
    alignment?: "left" | "center" | "right";
  };
  logo: {
    visible: boolean;
    url: string | null;
    width?: string;
    height?: string;
    className?: string;
  };
  formStyles: {
    [key: string]: React.CSSProperties | any;
  };
  automatedEmail: {
    recipient?: string;
    subject?: string;
    body?: string;
  };
  automatedResponse?: {
    companyName: string;
    message: string;
    processedMessage: string;
  };
  formData: Record<string, any>;
  submissionStatus: string;
  formError: string;
  customCSS: {
    enabled: boolean;
    css: string | null;
  };
}

export const initialCustomFormState: FormConfigType = {
  ownerEmail: "",
  layout: {
    width: "1095px",
    padding: "53px",
    borderRadius: "20px",
    backgroundColor: "#fff",
  },
  header: {
    title: "Email Audit Engine",
    className: "header-class",
    visible: true,
  },
  description: {
    text: "",
    className: "description-class",
    visible: false,
  },
  formFields: [
    {
      type: "email",
      name: "email",
      label: "Email",
      placeholder: "Email",
      required: true,
      errorText: "Email is required",
      width: "50%",
      className: "email-field",
      defaultValue: "",
    },
    {
      type: "text",
      name: "mailchimpKey",
      label: "Mailchimp API Key",
      placeholder: "Mailchimp API Key",
      required: true,
      errorText: "Mailchimp API Key is required",
      width: "50%",
      className: "",
      defaultValue: "",
    },
    {
      type: "text",
      name: "userName",
      label: "Name",
      placeholder: "John Doe",
      required: false,
      errorText: "Name is required",
      width: "50%",
      className: "",
      defaultValue: "",
    },
  ],
  button: {
    text: "Get Free Report",
    className: "",
  },
  logo: {
    visible: false,
    url: "https://images.prismic.io/kadima-digital/65977bd3531ac2845a2721a8_Group.png?auto=format%2Ccompress&fit=max&w=3840",
    width: "100px",
    height: "50px",
    className: "logo-class",
  },
  formStyles: {
    Title: {
      fontSize: "24px",
      color: "#336DFF",
      className: "title-style",
    },
    Description: {
      fontSize: "14px",
      className: "description-style",
    },
    FieldLabel: {
      fontSize: "16px",
      color: "#333",
      requiredColor: "#f00",
      className: "label-style",
    },
    FieldText: {
      fontSize: "16px",
      color: "#333",
      backgroundColor: "#f5f5f5",
      className: "input-style",
    },
    Button: {
      fontSize: "16px",
      color: "#fff",
      backgroundColor: "#336DFF",
      className: "button-style",
    },
  },
  automatedEmail: {
    recipient: "example@email.com",
    subject: "Form Submission",
    body: "A new form submission has been received.",
  },
  automatedResponse: {
    companyName: "",
    message: "",
    processedMessage: "",
  },
  formData: {},
  submissionStatus: "",
  formError: "",
  customCSS: {
    enabled: false,
    css: null,
  },
};
