"use client";
import { useState, useEffect } from "react";
import cn from "@/utils/cn";
import Image from "next/image";
import EAEIcon from "@/assets/icons/eae-icon.svg";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { EAELogo } from "@/assets/svg-icons/svg-icons";
import { useZStore } from "@/utils/z-store";
import TextComponent from "./TextComponent";
import { GoSidebarCollapse, GoSidebarExpand } from "react-icons/go";
import PremiumCardUpgrade from "./modals/PremiumCardUpgrade";

type NavItem = {
  name: string;
  route: string;
  defaultIcon: string;
  activeIcon: string;
  isDisabled?: boolean;
};

type SideNavProps = {
  navs: NavItem[];
  className?: string;
};

const SideNav = ({ navs, className = "" }: SideNavProps) => {
  const pathname = usePathname();
  const { data } = useZStore();
  const [shouldRender, setShouldRender] = useState(false);
  const [collapseSideNav, setCollapseSideNav] = useState(true); // State for collapse

  useEffect(() => {
    setShouldRender(data.sideBarVisible);
  }, [data.sideBarVisible]);

  // Add new useEffect for responsive behavior
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1920) {
        // Auto expand on 2xl
        setCollapseSideNav(false);
      } else if (window.innerWidth < 1920) {
        // Auto collapse below xl
        setCollapseSideNav(true);
      }
    };

    // Set initial state
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const hiddenSideNav = ["edit", "form-builder", "download-report"];
  const route = pathname.split("/")[2];

  if (hiddenSideNav.includes(route) || !shouldRender) return null;

  // Function to toggle collapse state
  const toggleCollapse = () => {
    setCollapseSideNav((prev) => !prev);
  };

  return (
    <>
      {/* Overlay */}
      {!collapseSideNav && window.innerWidth < 1920 && (
        <div
          className="fixed inset-0 z-40 bg-black/50 transition-opacity duration-300"
          onClick={() => setCollapseSideNav(true)}
        />
      )}

      <div className="">
        <div
          className={cn(
            "h-full bg-white text-black",
            // "transition-all duration-300 ease-in-out",
            "opacity-100 translate-x-0",
            "flex flex-col justify-between w-fit",
            collapseSideNav
              ? "w-[75px] xl:w-[103px] px-[14px] xl:px-5"
              : "2xl:static fixed w-[258px] xl:w-[345px] px-[34px] xl:px-[46px]",
            "z-50",
            className,
          )}
        >
          <div className="">
            <Logo collapseSideNav={collapseSideNav} />
            <NavList
              navs={navs}
              pathname={pathname}
              modalName={data.modal.modalName || ""}
              collapseSideNav={collapseSideNav} // Pass collapse state
            />
          </div>

          <div className="">
            {!collapseSideNav && (
              <PremiumCardUpgrade
                className={cn("mb-[51px] absolute bottom-[47.5px]")}
              />
            )}

            <TextComponent
              type="h3"
              className={cn(
                "flex w-fit cursor-pointer items-center space-x-2.5 xl:space-x-5 font-normal text-kd-lte-blue mb-10",
                collapseSideNav ? "mx-auto" : "px-5",
              )}
              onClick={toggleCollapse}
            >
              {collapseSideNav ? (
                <GoSidebarCollapse className="size-4 text-kd-gray-text xl:size-6" />
              ) : (
                <GoSidebarExpand className="size-4 text-kd-gray-text xl:size-6" />
              )}
              {!collapseSideNav && <div className="">Collapse Menu</div>}
            </TextComponent>
          </div>
        </div>
        <div
          className={cn(
            "h-full  bg-black/10",
            collapseSideNav ? "hidden" : "w-[75px] xl:w-[103px]",
          )}
        ></div>
      </div>
    </>
  );
};

const Logo = ({ collapseSideNav }: { collapseSideNav: boolean }) => (
  <div
    className={cn(
      "h-20 xl:h-[120px] w-full flex justify-center items-center mb-[35px]",
    )}
  >
    {!collapseSideNav ? (
      <div>
        <Image
          alt=""
          src={EAEIcon}
          // className="hidden object-contain md:block"
        />
      </div>
    ) : (
      <EAELogo className="size-[29px] xl:size-[39px]" />
    )}
  </div>
);

const NavList = ({
  navs,
  pathname,
  modalName,
  collapseSideNav,
}: {
  navs: NavItem[];
  pathname: string;
  modalName: string;
  collapseSideNav: boolean;
}) => (
  <ul
    className={cn(
      // "space-y-5 md:space-y-[21.3px] xl:space-y-[32px]",
      "space-y-4 xl:space-y-5",
    )}
  >
    {navs.map((nav, index) => (
      <NavItemComponent
        key={index}
        nav={nav}
        pathname={pathname}
        modalName={modalName}
        collapseSideNav={collapseSideNav} // Pass collapse state
      />
    ))}
  </ul>
);

const NavItemComponent = ({
  nav,
  pathname,
  modalName,
  collapseSideNav,
}: {
  nav: NavItem;
  pathname: string;
  modalName: string;
  collapseSideNav: boolean;
}) => {
  const { name, route, activeIcon, defaultIcon, isDisabled } = nav;
  const isLinkDisabled =
    nav.name !== "Dashboard" &&
    (isDisabled ||
      modalName === "mailchimp-api-key" ||
      modalName === "dashboard-welcome");

  return (
    <div className="group relative">
      <Link
        href={isLinkDisabled ? "#" : route}
        className={cn(
          // "px-3 2xl:px-[30px] md:px-[19px]",
          "flex items-center justify-center group-hover:bg-kd-lte-blue py-3 xl:py-[18.5px] w-fit md:rounded-[12px] rounded-[10.19px] xl:rounded-[18px]",
          "transition-all duration-300 ease-in-out",
          // "px-4 space-x-3",
          collapseSideNav
            ? "xl:size-[60px] size-[45px]"
            : "w-fit h-fit px-4 space-x-3",
          {
            "bg-kd-lte-blue": pathname === route,
            "opacity-50 group-hover:bg-[#8E8E9380] text-[#979797] cursor-not-allowed":
              isLinkDisabled,
          },
        )}
      >
        <NavIcon
          className={""}
          defaultIcon={defaultIcon}
          activeIcon={activeIcon}
          isActive={pathname === route}
        />
        {/* Hide text when collapsed */}
        {!collapseSideNav && (
          <NavText name={name} isActive={pathname === route} />
        )}
      </Link>
      {isDisabled && <ComingSoonTooltip />}
    </div>
  );
};

const NavIcon = ({
  defaultIcon,
  activeIcon,
  isActive,
  className,
}: {
  defaultIcon: string;
  activeIcon: string;
  isActive: boolean;
  className?: string;
}) => (
  <div
    className={cn(
      "size-[18px]  xl:size-[30px]",
      // "md:mr-[16px] 2xl:mr-[22.5px]",
      className,
    )}
  >
    <Image
      src={defaultIcon}
      alt="icon"
      className={cn("group-hover:hidden", { hidden: isActive })}
    />
    {activeIcon && (
      <Image
        src={activeIcon}
        alt="icon"
        className={cn("hidden group-hover:block", { block: isActive })}
      />
    )}
  </div>
);

const NavText = ({ name, isActive }: { name: string; isActive: boolean }) => (
  <TextComponent
    type="h3"
    className={cn(
      "text-[#231F20] group-hover:text-white group-hover:font-semibold font-normal md:flex",
      "transition-all duration-300 ease-in-out",
      { "text-white font-semibold": isActive },
    )}
  >
    {name}
  </TextComponent>
);

const ComingSoonTooltip = () => (
  <div className="absolute bottom-full left-7 hidden text-nowrap group-hover:flex">
    <span className="relative rounded-lg bg-[#336DFF] px-2 py-1 text-xs text-white">
      Coming Soon
      <span className="absolute left-[18px] top-full size-0 -translate-x-1/2 border-x-8 border-t-8 border-x-transparent border-t-[#336DFF]"></span>
    </span>
  </div>
);

export default SideNav;
