import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/react-quill/dist/quill.snow.css");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/cropperjs/dist/cropper.css");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/next/font/google/target.css?{\"path\":\"src/app/(dashboard)/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"display\":\"swap\",\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/_components/modal-wrapper.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/_components/sidenav.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/_components/header.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/bar-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/comments-active-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/comments-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/dashboard-active-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/dashboard-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/download-report-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/edit-report-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/export-report.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/form-editor-automated-mail.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/form-editor-brush.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/form-editor-custom-css.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/form-editor-pencil.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/form-editor-report.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/list-view-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/map-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/new-edit-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/overview-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/report-icon-blue.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/reports-active-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/reports-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/settings-active-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/settings-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/suggestions-active-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/suggestions-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/(dashboard)/dashboard/icons/timeline-icon.svg");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/globals.css");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/components/SessionWrapper.tsx")