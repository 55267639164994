// store/slices/customReportSlice.ts

import { CustomReport } from "../types/store";

export interface CustomReportState {
  customReports: CustomReport[];
  selectedCustomReport: string | null;
}

export interface CustomReportActions {
  addCustomReport: (name: string) => string;
  setSelectedCustomReport: (id: string) => void;
  addDataToCustomReport: (reportId: string, dashboardName: string) => void;
  updateCustomReportOrder: (reportId: string, newData: string[]) => void;
  removeDataFromCustomReport: (reportId: string, dashboardId: string) => void;
  setCustomReports: (reports: CustomReport[]) => void;
  updateCustomReport: (id: string, name: string) => void;
  deleteCustomReport: (id: string) => void;
}

export const customReportSlice = (
  set: any,
): { data: CustomReportState; actions: CustomReportActions } => ({
  data: {
    customReports: [
      {
        id: "1",
        name: "Performance Report",
        type: "template",
        data: [
          "AUTOMATION_DASHBOARD",
          "PERFORMANCE_DASHBOARD",
          "CAMPAIGN_DASHBOARD",
        ],
      },
    ],
    selectedCustomReport: "1",
  },
  actions: {
    // addCustomReport: (name: string) => {
    //   const id = Date.now().toString(); // Simple ID generation

    //   set((state: any) => {
    //     state.data.customReports.push({ id, name, data: [] });
    //   });

    //   return id;
    // },
    addCustomReport: (name: string) => {
      const id = Date.now().toString();

      set((state: any) => {
        const firstDashboardId = state.data.dashboardData[0]?.dashboardId;

        if (!firstDashboardId) {
          throw new Error("No dashboards available");
        }

        state.data.customReports.push({
          id,
          name,
          data: [firstDashboardId], // Initialize with first dashboard
        });
      });

      return id;
    },
    setSelectedCustomReport: (id) => {
      set((state: any) => {
        state.data.selectedCustomReport = id;
      });
    },
    addDataToCustomReport: (reportId: string, dashboardId: string) => {
      set((state: any) => {
        const report = state.data.customReports.find(
          (r: any) => r.id === reportId,
        );
        if (report && !report.data.includes(dashboardId)) {
          report.data.push(dashboardId);
        }
      });
    },
    updateCustomReportOrder: (reportId: string, newOrder: string[]) => {
      set((state: any) => {
        const report = state.data.customReports.find(
          (r: any) => r.id === reportId,
        );
        if (report) {
          report.data = newOrder;
        }
      });
    },
    removeDataFromCustomReport: (reportId: string, dashboardId: string) => {
      set((state: any) => {
        const report = state.data.customReports.find(
          (r: any) => r.id === reportId,
        );
        if (report) {
          report.data = report.data.filter((id: any) => id !== dashboardId);
        }
      });
    },
    setCustomReports: (reports: CustomReport[]) => {
      set((state: any) => {
        state.data.customReports = reports;
      });
    },

    updateCustomReport: (id: string, name: string) => {
      set((state: any) => {
        const reportIndex = state.data.customReports.findIndex(
          (r: CustomReport) => r.id === id,
        );
        if (reportIndex !== -1) {
          state.data.customReports[reportIndex].name = name;
        }
      });
    },

    deleteCustomReport: (id: string) => {
      set((state: any) => {
        // Remove the report
        state.data.customReports = state.data.customReports.filter(
          (report: CustomReport) => report.id !== id,
        );

        // Update selected report if needed
        if (state.data.selectedCustomReport === id) {
          state.data.selectedCustomReport =
            state.data.customReports.length > 0
              ? state.data.customReports[0].id
              : null;
        }
      });
    },
  },
});
