import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/@prismicio/next/dist/PrismicNextImage.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/@prismicio/next/dist/PrismicNextLink.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/@prismicio/next/dist/PrismicPreviewClient.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/next/font/google/target.css?{\"path\":\"src/app/(website)/layout.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"],\"display\":\"swap\",\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--font-poppins\"}],\"variableName\":\"poppins\"}");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/node_modules/react-hot-toast/dist/index.mjs");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/_components/cta-banner.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/_components/footer/footer.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/_components/header/index.tsx");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/app/globals.css");
import(/* webpackMode: "eager" */ "/home/runner/work/kadima-digital/kadima-digital/src/components/SessionWrapper.tsx")