"use client";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import InputField from "@/components/input-field";
import Button from "@/components/buttons";
import cn from "@/utils/cn";
import Image from "next/image";
import CloseIcon from "./assets/close-icon.svg";
import { useZStore } from "@/utils/z-store";
import { signIn, signOut } from "next-auth/react";
import { useRouter } from "next/navigation";
import { FcGoogle } from "react-icons/fc";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import React from "react";

const signInSchema = z.object({
  email: z.string().email("Please enter a valid email address."),
  password: z
    .string()
    .min(8, "Your password must be at least 8 characters long.")
    .regex(/[A-Z]/, "Your password must contain at least one capital letter.")
    .regex(/[0-9]/, "Your password must contain at least one number."),
});

type SignInFormData = z.infer<typeof signInSchema>;

interface SignInModalProps {
  inviteToken?: string;
  className?: string;
}

const SignInModal: React.FC<SignInModalProps> = ({ inviteToken }) => {
  const { actions } = useZStore();
  const router = useRouter();
  const [isLoading, setIsLoading] = React.useState(false);
  const [formError, setFormError] = React.useState<string | null>(null);

  const methods = useForm<SignInFormData>({
    resolver: zodResolver(signInSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    setError,
  } = methods;

  const onSubmit = async (data: SignInFormData) => {
    setIsLoading(true);
    setFormError(null);

    try {
      await signOut({ redirect: false });

      const response = await signIn("credentials", {
        email: data.email,
        password: data.password,
        redirect: false,
      });

      if (!response?.error) {
        actions.resetStore();
        router.push("/dashboard/home");
        router.refresh();
        actions.closeModal();
      } else {
        setFormError("Invalid email or password. Please try again.");
      }
    } catch (error) {
      setFormError("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    setFormError(null);

    try {
      await signOut({ redirect: false });

      if (inviteToken) {
        localStorage.setItem("inviteToken", inviteToken);
      }
      actions.resetStore();
      await signIn("google", {
        callbackUrl: "/dashboard/home",
        redirect: true,
      });
      actions.closeModal();
    } catch (error) {
      setFormError("Unable to sign in with Google. Please try again.");
      setIsLoading(false);
    }
  };

  const handleForgotPassword = () => {};

  return (
    <div>
      <div
        className={cn(
          "relative min-h-[336px] w-[306px] rounded-[16px] bg-white md:min-h-[317px] md:w-[438px] 2xl:min-h-[488px] 2xl:w-[730px] 2xl:rounded-[20px]",
          "pt-[26px] md:pt-[25px] 2xl:pt-[63px] pl-[25px] md:pl-[40px] 2xl:pl-[66px] pr-[26px] md:pr-[42px] 2xl:pr-[70px] pb-[28px] md:pb-[28px] 2xl:pb-[55px]",
          "2xl:scale-90",
        )}
      >
        <h2 className="mb-[9px] text-[25px] font-semibold leading-[30px] text-[#05004E] md:mb-0.5 md:text-[25px] md:leading-[37.5px] 2xl:text-[34px] 2xl:leading-[40px]">
          Welcome Back
        </h2>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            {formError && (
              <div className="my-4 rounded-lg bg-red-50 p-3 text-sm text-red-500">
                {formError}
              </div>
            )}

            <div className="mt-4 2xl:mt-6">
              <InputField
                id="sign-in-email"
                name="email"
                type="text"
                placeholder="hello@emailauditengine.com"
                className="mb-[5px] flex h-[45px] w-full rounded-[10px] bg-[#F9FAFB] px-[24px] font-normal 2xl:mb-[27px] 2xl:h-[60px] 2xl:rounded-2xl"
                label="Username or Email"
                labelClassName="text-[11px] font-medium leading-[13px] 2xl:text-base 2xl:leading-[20px] mb-2 leading-[12px] whitespace-nowrap"
                errorTextPosition="top"
              />
              <div className="">
                <InputField
                  name="password"
                  label="Password"
                  type="password"
                  className="flex h-[45px] w-full rounded-[10px] bg-[#F9FAFB] px-[24px] font-normal tracking-[4px] 2xl:h-[60px] 2xl:rounded-2xl"
                  placeholder="***"
                  labelClassName="text-[11px] font-medium leading-[13px] 2xl:text-base 2xl:leading-[20px] mb-2 leading-[12px] whitespace-nowrap"
                  isRequired={true}
                  errorTextPosition="top"
                />
                <p
                  onClick={handleForgotPassword}
                  className="mt-2 cursor-pointer text-right text-[9px] leading-[16px] text-[#336DFF] md:text-[10.8px] md:leading-[12px] 2xl:mt-[9px] 2xl:text-[14px] 2xl:leading-[20px]"
                >
                  Forgot password?
                </p>
              </div>
            </div>

            <div className="mt-2 flex items-center justify-center space-x-[10px] md:space-x-[15px] 2xl:mt-0 2xl:space-x-[30px]">
              <Button
                type="submit"
                variant="primary"
                disabled={isLoading}
                className="flex h-[32px] w-[119.6px] items-center justify-center whitespace-nowrap rounded-lg px-4 text-[12.8px] font-semibold leading-5 2xl:h-10 2xl:w-[150px] 2xl:text-base 2xl:leading-[24px]"
              >
                {isLoading ? (
                  <AiOutlineLoading3Quarters className="animate-spin" />
                ) : (
                  "Continue"
                )}
              </Button>
            </div>
          </form>
        </FormProvider>

        <div className="my-5 flex items-center justify-center space-x-5 text-[#E1E1E1] 2xl:my-[22px]">
          <div className="h-px w-1/5 bg-[#E1E1E1]"></div>
          <p>or</p>
          <div className="h-px w-1/5 bg-[#E1E1E1]"></div>
        </div>

        <div className="mt-4 flex flex-col space-y-[16px]">
          <Button
            onClick={handleGoogleSignIn}
            disabled={isLoading}
            className="flex h-10 items-center justify-center space-x-2 rounded-xl border border-[#96A5B8] bg-white text-kd-gray-text 2xl:h-[60px] 2xl:rounded-[16px]"
          >
            {isLoading ? (
              <AiOutlineLoading3Quarters className="animate-spin" />
            ) : (
              <>
                <FcGoogle className="size-5" />
                <span>Sign in with Google</span>
              </>
            )}
          </Button>
        </div>

        <p className="mt-[22px] text-center text-[9px] font-medium leading-[16px] text-[#D8D8D8] md:text-[10.8px] md:leading-[12px] 2xl:mt-[28px] 2xl:text-[14px] 2xl:leading-[20px]">
          {"Don't have an account?"}
          <span
            onClick={() => actions.openModal({ modalName: "sign-up" })}
            className="cursor-pointer text-[9px] leading-[16px] text-[#336DFF] md:text-[10.8px] md:leading-[12px] lg:text-[14px] lg:leading-[20px]"
          >
            {` Sign up `}
          </span>
        </p>

        <div className="" onClick={() => actions.closeModal()}>
          <Image
            src={CloseIcon}
            alt="close icon"
            className="absolute right-5 top-5 cursor-pointer hover:opacity-60"
          />
        </div>
      </div>
    </div>
  );
};

export default SignInModal;
