import ActiveDashboardIcon from "./dashboard-active-icon.svg";
import DashboardIcon from "./dashboard-icon.svg";
import SettingsIcon from "./settings-icon.svg";
import ActiveSettings from "./settings-active-icon.svg";
import CommentsIcon from "./comments-icon.svg";
import SuggestionsIcon from "./suggestions-icon.svg";
import ReportsIcon from "./reports-icon.svg";
import ReportsIconBlue from "./report-icon-blue.svg";
import ReportsActiveIcon from "./reports-active-icon.svg";
import SuggestionsActiveIcon from "./suggestions-active-icon.svg";
import CommentsActiveIcon from "./comments-active-icon.svg";
import MapIcon from "./map-icon.svg";
import OverviewIcon from "./overview-icon.svg";
import TimelineIcon from "./timeline-icon.svg";
import BarIcon from "./bar-icon.svg";
import ListIcon from "./list-view-icon.svg";
import FormEditorPencilIcon from "./form-editor-pencil.svg";
import FormEditorBrushIcon from "./form-editor-brush.svg";
import FormEditorAutomatedMailIcon from "./form-editor-automated-mail.svg";
import FormEditorCSSIcon from "./form-editor-custom-css.svg";
import FormEditorReportIcon from "./form-editor-report.svg";
import EditReportIcon from "./edit-report-icon.svg";
import ExportReportIcon from "./export-report.svg";
import DownloadReportIcon from "./download-report-icon.svg";
import NewEditIcon from "./new-edit-icon.svg";
export {
  ActiveDashboardIcon,
  DashboardIcon,
  SettingsIcon,
  ActiveSettings,
  CommentsIcon,
  SuggestionsIcon,
  ReportsIcon,
  ReportsActiveIcon,
  SuggestionsActiveIcon,
  CommentsActiveIcon,
  OverviewIcon,
  TimelineIcon,
  BarIcon,
  ListIcon,
  MapIcon,
  FormEditorPencilIcon,
  FormEditorBrushIcon,
  FormEditorAutomatedMailIcon,
  FormEditorCSSIcon,
  FormEditorReportIcon,
  ReportsIconBlue,
  EditReportIcon,
  ExportReportIcon,
  DownloadReportIcon,
  NewEditIcon,
};
