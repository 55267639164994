"use client";
import { useEffect, useState } from "react";
import Image from "next/image";
import Button from "@/components/buttons";

import { useZStore } from "@/utils/z-store";
import { FaMailchimp } from "react-icons/fa";
import ConnectedCheckIcon from "@/assets/icons/connected-icon.svg";
import { setupMailchimp } from "@/app/(dashboard)/dashboard/utils/mailchimp-utils/setup-mailchimp";
import { getCampaignsAndLists } from "@/app/(dashboard)/dashboard/utils/mailchimp-utils/process-mailchimp-data";
import { getAllMailchimpEmailActivities } from "@/app/(dashboard)/dashboard/utils/mailchimp-utils/get-campaign-data";
import { getSubscribersUnsubscribersData } from "@/app/(dashboard)/dashboard/utils/mailchimp-utils/get-subs-unsubs-data";
import { getAllAbuseReports } from "@/app/(dashboard)/dashboard/utils/mailchimp-utils/get-abuse-report";
import { getEcommerceOrders } from "@/app/(dashboard)/dashboard/utils/mailchimp-utils/ecommerce-orders";
import axios from "axios";
import toast from "react-hot-toast";
import cn from "@/utils/cn";
import TextComponent from "../TextComponent";
import { IoCloseCircleOutline } from "react-icons/io5";
import { FiClock } from "react-icons/fi";
import { MailchimpDataSchema } from "@/app/zustand-store/types/store";
import { processEcommerceData } from "@/app/(dashboard)/dashboard/utils/mailchimp-utils/process-data/process-ecommerce-orders-1";
import processMailchimpData from "@/app/(dashboard)/dashboard/utils/mailchimp-utils/process-data/process-campaigns";
import { ProcessedMailchimpData } from "@/app/(dashboard)/dashboard/utils/mailchimp-utils/process-data/mailchimp-data-types";
import {
  postMailchimpData,
  postProcessedData,
} from "@/app/(dashboard)/dashboard/utils/mailchimp";
const MailchimpAPISettingsModal = () => {
  const [error, setError] = useState("");
  const [connected, setConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div
      className={cn(
        "min-h-[331px] md:min-h-min w-[262px] overflow-hidden rounded-[20px] bg-white md:h-[233px] md:w-[549px] xl:h-[351px] xl:w-[1116px]",
        "px-[35px] py-[26px]",
      )}
    >
      <div className="flex h-full flex-col  md:flex-row">
        <div className=" flex grow flex-col items-center justify-center text-black">
          <div className="flex max-w-[581.95px] flex-col items-center text-center md:items-start md:text-start">
            <TextComponent
              type="h2"
              className="pr-2 text-left text-[#05004E] md:w-full xl:mb-[6px]"
            >
              Setup Integration
            </TextComponent>

            <TextComponent
              type="body"
              className="w-full pt-2 text-left text-[#96A5B8] xl:pt-0"
            >
              Enter your
              <a
                href="https://admin.mailchimp.com/account/api/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="cursor-pointer text-[#336DFF]">
                  {` generate an API key `}
                </span>
                <div className="md:hidden">{"\n"}</div>
              </a>
              to integrate with Mailchimp.
            </TextComponent>

            <div className="">
              <MailchimpForm
                setError={setError}
                setConnected={setConnected}
                connected={connected}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            </div>
          </div>
        </div>
        <div className="mt-[30px] bg-white md:mt-0 md:w-[162px] xl:w-[372px]">
          <div className="flex h-full flex-col items-center justify-center px-[19px] text-center md:px-[14px] xl:px-10">
            <div className="flex size-10 items-center justify-center rounded-full bg-[#FFDD22] xl:size-20">
              <FaMailchimp className="size-full p-1" />
            </div>

            <TextComponent
              type="h3"
              className="mt-2.5 font-semibold text-[#05004E] xl:mt-[9.5px]"
            >
              Connect Mailchimp
            </TextComponent>

            <TextComponent type="body" className="mt-[5px] text-[#96A5B8]">
              Integrate Mailchimp to coordinate cross-channel email campaigns.
            </TextComponent>

            <TextComponent
              type="caption"
              className={`mt-[10px] inline-flex items-center ${
                connected
                  ? "text-[#00E096]"
                  : error
                    ? "text-[#FA5A7D]"
                    : isLoading
                      ? "text-[#336DFF]"
                      : ""
              }`}
            >
              {connected ? (
                <>
                  <Image alt="" src={ConnectedCheckIcon} className="mr-[7px]" />
                  Connected
                </>
              ) : error ? (
                <div className="flex">
                  <IoCloseCircleOutline className="text-sm xl:text-xl" />
                  <span className="ml-1">{error}</span>
                </div>
              ) : isLoading ? (
                <>
                  <FiClock className="text-sm xl:text-xl" />
                  <span className="ml-1">Connecting...</span>
                </>
              ) : null}
            </TextComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

type FormEvent = React.FormEvent<HTMLFormElement>;
interface MailchimpData {
  campaignsAndLists: any;
  emailActivities?: any;
  abuseReports?: any;
  subscribersData?: any;
  ecommerceOrders?: any;
}

const MailchimpForm = ({
  setConnected,
  connected,
  setError,
  isLoading,
  setIsLoading,
}: any) => {
  const [apiKey, setApiKey] = useState("");
  const [showError, setShowError] = useState(false);
  const { data, actions } = useZStore();
  const [isInvalidApiKey, setIsInvalidApiKey] = useState(false);
  // Main function for submitting API key
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    resetState();

    try {
      if (!(await validateApiKeyAndShowError(apiKey))) return;
      await Promise.all([fetchDashboardTemplate(), fetchSharedData()]);

      // Proceed with setting up Mailchimp and fetching data
      const mailchimpKeyId = await setupMailchimpWithKey(apiKey);

      if (mailchimpKeyId) {
        actions.setLoading({
          isLoading: true,
          message: "mailchimp data processing",
        });
        const { rawData, processedData } =
          await fetchMailchimpData(mailchimpKeyId);

        if (rawData && processedData) {
          actions.addMailchimpData(rawData);
          actions.setProcessedData({
            ...data.processedData,
            ...processedData,
          });

          // Post data to database
          // await Promise.all([
          //   postMailchimpData(data?.userDetails?.email || "", rawData),
          // ]);
          // await postProcessedData(
          //   data?.userDetails?.email || "",
          //   mailchimpKeyId,
          //   processedData,
          // );

          // // Start revenue data fetch in background
          // fetchRevenueData(mailchimpKeyId)
          //   .then(async (res) => {
          //     // Update store with revenue data
          //     const revenueData = res.data;
          //     actions.addProcessedData(revenueData);

          //     // Post revenue data to DB
          //     await Promise.all([
          //       // postMailchimpData(data?.userDetails?.email || "", {
          //       //   ...rawData,
          //       //   revenueData,
          //       // }),
          //       postProcessedData(
          //         data?.userDetails?.email || "",
          //         mailchimpKeyId,
          //         {
          //           ...processedData,
          //           revenueData,
          //         },
          //       ),
          //     ]);
          //   })
          //   .catch((error) => {
          //     console.error("Error processing revenue data:", error);
          //     showToast("Failed to fetch revenue data", "error");
          //   });

          closeModalWithDelay();
          setConnected(true);
        } else {
          showToast("Failed to fetch Mailchimp data", "error");
        }
      } else {
        showToast("Something went wrong", "error");
      }
    } catch (error) {
      handleError(error);
    } finally {
      resetLoadingState();
    }
  };

  // Fetch Mailchimp data using the new API endpoint
  const fetchMailchimpData = async (mailchimpKeyId: string) => {
    try {
      console.log("inside fetchMailchimpData...");
      const response = await axios.post("/api/mailchimp/fetch-and-process", {
        email: data?.userDetails?.email || "",
        mailchimpKeyId,
      });
      console.log("🚀 ~ fetchMailchimpData ~ response fetched");
      return response.data;
    } catch (error) {
      console.error("Error fetching Mailchimp data:", error);
      throw error;
    }
  };

  const fetchRevenueData = async (mailchimpKeyId: string) => {
    try {
      console.log("🚀 ~ started fetching revenue data:");

      const response = await axios.post("/api/mailchimp/fetch-revenue-data", {
        email: data?.userDetails?.email || "",
        mailchimpKeyId,
      });
      return response.data?.revenueData;
    } catch (error) {
      console.error("Error fetching Mailchimp data:", error);
      throw error;
    }
  };

  // Reset initial state
  const resetState = () => {
    setIsInvalidApiKey(false);
    setIsLoading(true);
    setError("");
  };

  // Validate the API key and show an error if invalid
  const validateApiKeyAndShowError = async (apiKey: string) => {
    const result = await validateApiKey(apiKey);
    if (!result.isValid) {
      result.errorType === "invalid" && setIsInvalidApiKey(true);
      setError(
        result.errorType === "api_down"
          ? "Mailchimp is currently unavailable, please try again later"
          : "Not connected",
      );
      setIsLoading(false);
    }
    return result.isValid;
  };

  // Set up Mailchimp and return the Mailchimp Key ID if successful
  const setupMailchimpWithKey = async (apiKey: string) => {
    const mailchimpKeyId = crypto.randomUUID();
    actions.addMailchimpApiKey("default", apiKey, mailchimpKeyId);
    actions.setSelectedMailchimpApiKey(mailchimpKeyId);

    const res = await setupMailchimp({
      mailchimpAccessTokenString: apiKey,
      email: data?.userDetails?.email || "",
      mailchimpKeyId,
    });

    // if (res?.success) setConnected(true);
    return res?.success ? mailchimpKeyId : null;
  };

  // Show toast notifications
  const showToast = (message: string, type: "success" | "error") => {
    toast[type](message, { position: "bottom-right" });
  };

  // Close modal with a delay
  const closeModalWithDelay = () => {
    setTimeout(() => actions.closeModal(), 2000);
  };

  // Reset loading state
  const resetLoadingState = () => {
    setIsLoading(false);
    actions.setLoading({ isLoading: false, message: "" });
  };

  // Handle errors
  const handleError = (error: any) => {
    console.error(error);
    setError("Something went wrong");
    setIsInvalidApiKey(true);
  };

  const fetchDashboardTemplate = async () => {
    // Fetch the dashboard template and set it in the z-store and database
    const dashboardTemplate = await axios.get("/api/dashboard-template");

    try {
      // Make an API call to update the dashboard data in the database
      const response = await axios.put("/api/users/dashboard", {
        dashboardData: dashboardTemplate.data,
        selectedDashboard: "",
      });
      actions.setDashboardData(dashboardTemplate.data);
      actions.setSelectedDashboard("PERFORMANCE_DASHBOARD");
      console.log("Dashboard data updated successfully:", response.data);
      console.log("Zustand dashboard data", data.dashboardData);
    } catch (error) {
      console.error("Error updating dashboard data in the database:", error);
    }
  };

  const fetchSharedData = async () => {
    try {
      const response = await axios.get("/api/shared-data");
      console.log("Shared data fetched:", response.data);

      // Merging the new data with the existing one
      const updatedDashboardData = {
        ...data.dashboardData,
        ...response?.data?.dashboardData,
      };

      actions.setDashboardData(updatedDashboardData);
    } catch (error) {
      console.error("Error fetching shared data:", error);
    }
  };

  const validateApiKey = async (key: string) => {
    const apiKeyPattern = /^[a-f0-9]{32}-us\d{1,2}$/;
    if (!apiKeyPattern.test(key))
      return { isValid: false, errorType: "invalid" };

    const [token, server] = key.split("-");
    try {
      const response = await axios.get(
        `/api/mailchimp/ping?apiKey=${encodeURIComponent(token)}&server=${encodeURIComponent(server)}`,
      );
      return { isValid: true };
    } catch (error) {
      let errorType: "invalid" | "api_down" = "invalid";

      if (axios.isAxiosError(error)) {
        if (error.response) {
          // Check for nested Mailchimp 503 error
          const mailchimpStatus = error.response.data?.error?.status;
          if (mailchimpStatus === 503) {
            errorType = "api_down";
          }
          // Check for 5xx errors from the proxy
          else if (error.response.status >= 500) {
            errorType = "api_down";
          }
        } else {
          // Network errors or no response
          errorType = "api_down";
        }
      } else if (
        error instanceof Error &&
        error.message.includes("Network Error")
      ) {
        errorType = "api_down";
      }

      console.error("Mailchimp API validation error:", error);
      return { isValid: false, errorType };
    }
  };

  return (
    <div className="relative">
      <form onSubmit={handleSubmit}>
        {isInvalidApiKey && (
          <TextComponent
            type="body"
            className="absolute my-[9px] text-[#FA5A7D]"
          >
            *Please enter a valid API key
          </TextComponent>
        )}

        <TextComponent type="body">
          <input
            disabled={connected}
            type="text"
            value={apiKey}
            placeholder="Enter your Mailchimp API Key"
            className={cn(
              "xl:mt-[33px]  mt-[29px] md:mt-[34px] xl:h-[60px] xl:w-[592.14px] xl:rounded-[16px] bg-[#F9FAFB] px-[24px]",
              "h-[30px] md:w-[248px] w-[168px]",
              "rounded-[8px] xl:rounded-[10.67px]",
              // "2xl:text-[16px] xl:text-[10.5px] text-[9px]",
              isInvalidApiKey ? "border-2 border-[#FA5A7D]" : "",
              "w-[204px] md:w-[280px] xl:w-[592.14px]",
            )}
            onChange={(e) => setApiKey(e.target.value)}
          />
        </TextComponent>
        <Button
          disabled={connected}
          type="submit"
          className={cn(
            "mt-[14px] bg-kd-lte-blue px-[23px] text-white xl:mt-[27px] w-[204px]",
            connected &&
              "bg-[#ECECEC] text-white hover:bg-[#ECECEC] hover:text-white border-none cursor-not-allowed",
          )}
        >
          <TextComponent type="button">Continue</TextComponent>
        </Button>
      </form>
    </div>
  );
};

export default MailchimpAPISettingsModal;
