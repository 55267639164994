import cn from "@/utils/cn";

interface TextProps {
  type: "title" | "h1" | "h2" | "h3" | "h4" | "body" | "caption" | "button";
  children: React.ReactNode;
  className?: string;
  renderHTML?: boolean;
  [key: string]: any;
}

const TextComponent: React.FC<TextProps> = ({
  type,
  children,
  className,
  renderHTML = false,
  ...props
}) => {
  const baseStyles = {
    title: "font-semibold",
    h1: "font-semibold",
    h2: "font-semibold",
    h3: "font-semibold",
    h4: "font-medium",
    body: "font-normal",
    caption: "font-normal",
    button: "font-semibold",
  };

  const textStyles = {
    title: `xl:text-[50px] text-[34px] `,
    h1: `xl:text-[40px] text-[27px]`,
    h2: `xl:text-[35px] text-[23px] text-[17px]s`,
    h3: `xl:text-[18px] text-[12px] text-[10px]s`,
    h4: `xl:text-[16px] text-[10.5px] text-[9px]s`,
    body: `xl:text-[14px] text-[9.5px] text-[8px]s`,
    caption: `xl:text-[12px] text-[8px] text-[6px]s`,
    button: `xl:text-[16px] text-[10.5px] text-[9px]s`,
  };

  // Only check for HTML if renderHTML prop is true
  const isHTML = (str: string) => {
    return typeof str === "string" && /<[a-z][\s\S]*>/i.test(str);
  };

  const linkStyles = `
    <style>
      a {
          color: #2563eb;
        }
    </style>
  `;

  // Only render HTML if renderHTML prop is true and content contains HTML
  if (renderHTML && typeof children === "string" && isHTML(children)) {
    return (
      <div
        className={cn(baseStyles[type], textStyles[type], className)}
        dangerouslySetInnerHTML={{ __html: linkStyles + children }}
        {...props}
      />
    );
  }

  return (
    <div
      className={cn(baseStyles[type], textStyles[type], className)}
      {...props}
    >
      {children}
    </div>
  );
};

export default TextComponent;
