// store/slices/processedDataSlice.ts

export interface ProcessedDataState {
  processedData: {
    campaignsData?: any;
    automationData?: any;
    allEmails?: any;
    ecommerceData?: any;
    revenueData?: any;
    subscribersData?: any;
    deliverabilityMetrics?: any;
  };
}

export interface ProcessedDataActions {
  setProcessedData: (data: any) => void;
  addProcessedData: (data: any) => void;
}

export const processedDataSlice = (
  set: any,
): { data: ProcessedDataState; actions: ProcessedDataActions } => ({
  data: {
    processedData: {},
  },
  actions: {
    setProcessedData: (data: any) => {
      set((state: any) => {
        state.data.processedData = data;
      });
    },
    addProcessedData: (data: any) => {
      set((state: any) => {
        state.data.processedData = { ...state.data.processedData, ...data };
      });
    }
  },
});
