import cn from "@/utils/cn";

type ButtonVariants = "primary" | "secondary" | "tertiary" | "xxs" | "social";

type ButtonProps = {
  variant?: ButtonVariants;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

const mainVariants = ["primary", "secondary", "tertiary", "xxs"];

export default function Button({
  variant = "primary",
  className,
  ...props
}: ButtonProps) {
  return (
    <button
      className={cn(
        "flex items-center justify-center text-[10.5px]  xl:text-base font-semibold",
        "xl:text-[16px] text-[10.5px]",

        mainVariants.includes(variant) && "px-3 py-1.5 xl:px-8-xl:py-2",

        "rounded-[8px]",

        variant === "primary" &&
          "bg-kd-lte-blue hover:bg-white hover:border border border-transparent hover:border-kd-lte-blue hover:text-kd-lte-blue text-white",
        // " bg-kd-lte-blue text-white hover:bg-kd-lte-blue hover:text-kd-white disabled:border-kd-lte-grey disabled:bg-kd-lte-grey disabled:font-bold disabled:text-[#D1D1D6]",

        variant === "secondary" &&
          "bg-[#ECECEC] hover:bg-white hover:border border border-transparent hover:border-[#ECECEC] text-kd-gray-text",
        // "  bg-kd-grey-button text-[#96A5B8] hover:bg-kd-white hover:text-kd-lte-blue disabled:border-kd-lte-grey disabled:bg-kd-lte-grey disabled:font-bold disabled:text-[#D1D1D6]",

        variant === "tertiary" &&
          "border-2 border-kd-white text-kd-white hover:bg-kd-white hover:text-[#61A1FF] disabled:border-[#F6F6F6] disabled:bg-[#F6F6F6] disabled:font-bold disabled:text-[#D1D1D6]",

        variant === "xxs" &&
          "border-2 border-[#E5E5EA] text-kd-black hover:bg-kd-lte-blue hover:border-kd-lte-blue hover:text-kd-white disabled:border-kd-lte-grey disabled:bg-kd-lte-grey disabled:text-[#C7C7CC]",

        variant === "social" &&
          "h-[40px] w-[40px] border-2 border-[#E5E5EA] text-kd-black hover:border-kd-white hover:bg-kd-white disabled:border-kd-lte-grey disabled:bg-kd-lte-grey disabled:text-[#C7C7CC]",

        //  variant==="tertiary" && "bg-kd-white text-kd-black disabled:bg-kd-lte-grey disabled:border-kd-lte-grey disabled:font-bold disabled:text-[#D1D1D6] hover:bg-kd-black hover:text-kd-white border-2 border-kd-black rounded-[100px]",
        className,
      )}
      {...props}
    ></button>
  );
}
